import { axiosCreativeBoard } from "../../../services/api";
// import { getTotalBoardCounts } from "../home/actions";
// import { AppToast } from "utils";

import {
  LOADING_BOARD_DETAILS,
  SET_BACKGROUND_DETAILS,
  SET_BACKGROUND_IMAGES,
  SET_BACKGROUND_IMAGES_LOADING,
  SET_BOARD_TITLE,
  SET_OWNER_INFO,
  SET_INVITED_USERS,
  SET_IS_INVITED_USER,
  SET_IS_PUBLIC,
  LIST_ADMIN_TEMPLATE_BOARDS_DATA,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_PAGE,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SEARCH,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SIZE_PER_PAGE,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_FIELD,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_ORDER,
  CLEAR_TEMPLATE_TABLE_DATA,
  REMOVE_LOADING_CARD_ID,
  EDITOR_LIST_CANVAS_BOARD,
  SET_FETCH_BOARD,
  SET_CANVAS,
  SET_CANVAS_CARD_COORDINATE,
  SET_ZOOM_LEVEL,
  PREVIEW_LIST_CANVAS_BOARD,
  SET_UNDO_STACK,
  SET_ACTIVE_CARD_DATA,
  SET_GRID,
} from "./actionTypes";
import {
  CREATE_UPDATE_CARD,
  DELETE_BOARD_CARD,
  DELETE_INVITED_USER,
  EDIT_BOARD_DETAILS,
  EDIT_BOARD_GRID,
  GET_BACKGROUND_IMAGES,
  LIST_ADMIN_TEMPLATE_BOARDS,
  PREVIEW_BOARD_DATA,
  UPDATE_CANVAS_CARD_COORDINATES,
  UPDATE_CARD_COORDINATES,
} from "../../../helper/url_helper";
import { toast } from "react-toastify";
import { SOMETHING_WANT_WRONG } from "../../../constants/ErrorMessage";
import { getTotalBoardCounts } from "../../Home/store/actions";

export const isFunc = func => !!func && typeof func === "function";

export const fetchNewBoardDetails =
  (boardId, { onUnAuthorized }) =>
  async dispatch => {
    try {
      dispatch(setFetchBoardLoading(true));
      const res = await axiosCreativeBoard.get(
        `${EDIT_BOARD_DETAILS}/${boardId}`,
      );
      if (res.status) {
        dispatch(setFetchBoardLoading(false));
        if (res.data?.data?.boardData) {
          const {
            background_data,
            background_type,
            board_name,
            invited_users,
            first_name,
            last_name,
            email,
            profile_image,
            is_invited,
            public_sharing,
            zoom_level,
            grid
          } = res.data.data.boardData;

          dispatch(setGrid(grid));
          dispatch(setIsInvitedUser(is_invited));
          dispatch(setBoardTitle(board_name));
          dispatch(setZoomLevel(zoom_level));
          dispatch(getTotalBoardCounts());
          dispatch(setEditorBoardData(res.data.data.boardData?.boardCards));
          dispatch(setPreviewBoardData(res.data.data?.boardData));
          dispatch(
            setBackgroundDetails({
              backgroundData: background_data,
              backgroundType: background_type,
            }),
          );
          dispatch(
            setOwnerInfo({
              name: `${first_name} ${last_name}`,
              imgUrl: profile_image,
              email: email,
            }),
          );
          dispatch(setInvitedUsers(invited_users || []));
          dispatch(setIsPublic(public_sharing));
        }
      }
    } catch (err) {
      dispatch(setFetchBoardLoading(false));
      // console.log("err :>> ", err);
      if (err?.response?.status === 401) {
        // if (onUnAuthorized) {
        // onUnAuthorized();
        // }
        if (isFunc(onUnAuthorized)) {
          onUnAuthorized();
        }
      }
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
  };

export const fetchEditBoardDetails =
  (boardId, onSuccess = () => {}) =>
  async dispatch => {
    try {
      dispatch(setFetchBoardLoading(true));
      const res = await axiosCreativeBoard.get(
        `${EDIT_BOARD_DETAILS}/${boardId}`,
      );
      if (res.status) {
        dispatch(setFetchBoardLoading(false));
        dispatch(setGrid(res.data.data.boardData.grid));
        if (res.data?.data?.boardData) {
          dispatch(setEditorBoardData(res.data.data.boardData?.boardCards));
        }

        onSuccess();
      }
    } catch (err) {
      dispatch(setFetchBoardLoading(false));
      // console.log("err :>> ", err);
      if (err?.response?.status === 401) {
        // if (onUnAuthorized) {
        // onUnAuthorized();
        // }
      }
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
  };

export const fetchPreviewBoardDetails =
  boardId => async (dispatch, getState) => {
    try {
      const { isAuth } = getState().Auth;
      dispatch(setLoadingBoardDetails(true));
      dispatch(setFetchBoardLoading(true));
      const res = await axiosCreativeBoard.get(
        `${PREVIEW_BOARD_DATA}/${boardId}`,
      );
      if (res.status) {
        dispatch(setLoadingBoardDetails(false));
        dispatch(setFetchBoardLoading(false));
        if (res.data?.data?.boardData) {
          const { background_data, background_type, board_name, zoom_level, grid } =
            res.data.data.boardData;

          dispatch(setGrid(grid));
          dispatch(setZoomLevel(zoom_level || 1));
          dispatch(setEditorBoardData(res.data.data.boardData?.boardCards));
          dispatch(setPreviewBoardData(res.data.data?.boardData));
          dispatch(setBoardTitle(board_name));
          isAuth && dispatch(getTotalBoardCounts());
          dispatch(
            setBackgroundDetails({
              backgroundData: background_data,
              backgroundType: background_type,
            }),
          );
        }
      }
    } catch (err) {
      dispatch(setLoadingBoardDetails(false));
      dispatch(setFetchBoardLoading(false));
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
  };

export const fetchBackgroundImages = payload => async dispatch => {
  try {
    dispatch(setBackgroundImagesLoading(true));
    const res = await axiosCreativeBoard.post(GET_BACKGROUND_IMAGES, payload);
    if (res.status) {
      dispatch(setBackgroundImagesLoading(false));
      if (res.data?.data?.background?.rows)
        dispatch(
          setBackgroundImages(
            res.data.data.background.rows.map(img => ({
              id: img.background_library_id,
              url: img.image_url,
            })),
          ),
        );
    }
  } catch (err) {
    dispatch(setBackgroundImagesLoading(false));
    toast.error(
      err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
    );
  }
};

export const createEditCard =
  (payload, boardId, cb = () => {}) =>
  async (dispatch, getState) => {
    try {
      const state = getState().Board;
      const { ...rest } = payload;
      const res = await axiosCreativeBoard.post(CREATE_UPDATE_CARD, {
        board_id: boardId,
        ...rest,
      });

      if (res.status) {
        dispatch(setCanvasData(!state.canvasLoading));
        cb(res.data.data.card.card_id);
      }
    } catch (err) {
      cb(err);
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
  };

export const updateCardsIndexes = payload => async (dispatch, getState) => {
  try {
    const state = getState().Board;
    const res = await axiosCreativeBoard.post(UPDATE_CARD_COORDINATES, {
      cards: payload.type === "reset" ? payload.resetElementCoords : payload,
      type: 2,
    });
    if (res.status) {
      dispatch(
        updateCardCoordinatesCanvas(
          payload.type === "reset" ? payload.resetElementCoords : payload,
        ),
      );
      payload.type === "reset" && dispatch(setCanvasData(!state.canvasLoading));
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
    );
  }
};

export const listAdminTemplateBoards = payload => {
  return async dispatch => {
    try {
      const res = await axiosCreativeBoard.post(
        LIST_ADMIN_TEMPLATE_BOARDS,
        payload,
      );
      if (res) {
        dispatch(getTemplateBoards(res.data.data));
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
  };
};

export const deleteCard = payload => async (dispatch, getState) => {
  try {
    const state = getState().Board;

    const res = await axiosCreativeBoard.post(DELETE_BOARD_CARD, payload);
    if (res.status) {
      dispatch(removeLoadingCardId(payload));
      dispatch(setCanvasData(!state.canvasLoading));
      toast.success(
        `${payload.action === 1 ? "Card Deleted" : "Card Restored"}`,
      );
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
    );
    dispatch(removeLoadingCardId(payload));
  }
};

export const duplicateCard =
  (payload, boardId, coords, date, cb = () => {}) =>
  async dispatch => {
    try {
      const { ...rest } = payload;

      dispatch(
        createEditCard(
          {
            ...rest,
            card_id: 0,
            x_coordinate: coords.x_coordinate,
            y_coordinate: coords.y_coordinate,
            date: date && date,
          },
          boardId,
          status => cb(status),
        ),
      );
    } catch (err) {
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
  };

export const updateCardCanvasCoords = payload => async dispatch => {
  try {
    const res = await axiosCreativeBoard.post(
      UPDATE_CANVAS_CARD_COORDINATES,
      payload,
    );
    if (res.status) {
      // dispatch(setCanvasData(!state.canvasLoading));
      dispatch(updateCardCoordinatesCanvas(payload));
      // dispatch(setEditorBoardData(updatedCards));
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
    );
  }
};

export const handleDeleteInvitedUser = (payload) => 
  async dispatch => {
    try{
      const res = await axiosCreativeBoard.post(DELETE_INVITED_USER, {
        invite_board_user_id: payload
      });
      if (res.status) {
        if (res.data?.data?.invitedUser) {
          dispatch(setInvitedUsers(res.data.data.invitedUser));
        }
        toast.success(res?.data?.message);
      }
    }
    catch(err){
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
}

export const setBoardGrid = ({id, isGrid}) => 
  async dispatch => {
    try{
      const res = await axiosCreativeBoard.post(EDIT_BOARD_GRID, {
        board_id: +id,
        grid: +isGrid,
    });
      if (res.status) {
        dispatch(setGrid(isGrid));
      }
    }
    catch(err){
      toast.error(
        err?.response?.data?.message || err?.message || SOMETHING_WANT_WRONG,
      );
    }
}

export const setGrid = data => ({
  type: SET_GRID,
  payload: data
})

export const setEditorBoardData = data => ({
  type: EDITOR_LIST_CANVAS_BOARD,
  payload: data,
});

export const setPreviewBoardData = data => ({
  type: PREVIEW_LIST_CANVAS_BOARD,
  payload: data,
});

export const setLoadingBoardDetails = data => ({
  type: LOADING_BOARD_DETAILS,
  payload: data,
});

export const setBoardTitle = data => ({
  type: SET_BOARD_TITLE,
  payload: data,
});

export const setBackgroundDetails = data => ({
  type: SET_BACKGROUND_DETAILS,
  payload: data,
});

export const setBackgroundImagesLoading = data => ({
  type: SET_BACKGROUND_IMAGES_LOADING,
  payload: data,
});

export const setBackgroundImages = data => ({
  type: SET_BACKGROUND_IMAGES,
  payload: data,
});

export const setZoomLevel = data => ({
  type: SET_ZOOM_LEVEL,
  payload: data,
});

export const removeLoadingCardId = data => ({
  type: REMOVE_LOADING_CARD_ID,
  payload: data,
});

export const setUndoStackData = data => ({
  type: SET_UNDO_STACK,
  payload: data,
});

export const setOwnerInfo = data => ({
  type: SET_OWNER_INFO,
  payload: data,
});

export const setInvitedUsers = data => ({
  type: SET_INVITED_USERS,
  payload: data,
});

export const setIsInvitedUser = data => ({
  type: SET_IS_INVITED_USER,
  payload: data,
});

export const setIsPublic = data => ({
  type: SET_IS_PUBLIC,
  payload: data,
});

export const setFetchBoardLoading = data => ({
  type: SET_FETCH_BOARD,
  payload: data,
});

export const setCanvasData = data => ({
  type: SET_CANVAS,
  payload: data,
});

export const updateCardCoordinatesCanvas = data => ({
  type: SET_CANVAS_CARD_COORDINATE,
  payload: data,
});

export const getTemplateBoards = data => ({
  type: LIST_ADMIN_TEMPLATE_BOARDS_DATA,
  payload: data,
});

export const setAdminTemplateTablePage = data => ({
  type: SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_PAGE,
  payload: data,
});

export const setAdminTemplateTableSizePerPage = data => ({
  type: SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SIZE_PER_PAGE,
  payload: data,
});

export const setAdminTemplateTableSortField = data => ({
  type: SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_FIELD,
  payload: data,
});

export const setAdminTemplateTableSortOrder = data => ({
  type: SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_ORDER,
  payload: data,
});

export const setAdminTemplateTableSearch = data => ({
  type: SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SEARCH,
  payload: data,
});

export const clearAdminTemplateTable = data => ({
  type: CLEAR_TEMPLATE_TABLE_DATA,
  payload: data,
});

export const setActiveCardData = data => ({
  type: SET_ACTIVE_CARD_DATA,
  payload: data,
});
