//Authentication
export const USER_PROFILE = "/getUserProfileDetail";
export const LOG_OUT_USER = "/logOutUser";

//Header
export const SIDE_MENU = "/getDashboardPageSideMenu";
export const NINE_DOTS = "/getDashboardHeaderNineDots";
export const NINE_DOTS_V2 = "/getDashboardHeaderNineDotsV2";

//Home
export const LIST_BOARD = "/listBoard";
export const DUPLICATE_BOARD = "/duplicateBoard";
export const DELETE_BOARD = "deleteBoard";

//canvas API
export const GET_BACKGROUND_IMAGES = "/listBackgroundLibrary";
export const EDIT_BOARD_DETAILS = "/EditBoardDetail";
export const PREVIEW_BOARD_DATA = "/previewBoardData";
export const CREATE_UPDATE_CARD = "/createUpdateBoardCard";
export const UPDATE_CARD_COORDINATES = "/updateCardCoordinate";
export const LIST_ADMIN_TEMPLATE_BOARDS = "/listAdminBoard";
export const DELETE_BOARD_CARD = "/deleteBoardCard";
export const UPDATE_CANVAS_CARD_COORDINATES = "/updateCanvasCardCoordinate";
export const EDIT_BOARD_ZOOM_LEVEL = "/editBoardZoomLevel";
export const CREATE_BOARD = "/createBoard";

//Board
export const EDIT_BOARD_NAME = "/editBoardName";
export const EDIT_BOARD_BACKGROUND = "/editBoardBackground";
export const UPDATE_BOARD_IMAGE = "/updateBoardImage"
export const DELETE_INVITED_USER = "./deleteInvitedUser";
export const EDIT_BOARD_GRID = "/editBoardGrid";

//Add Grow Content
export const GET_CATEGORIES = "/getCategories";
export const MEMBERSHIP_VOLUMES = "/getUserMembershipVolumes";
export const GET_ALL_BOARD_SERIES = "/getAllBoardsBySeries";
export const GET_ALL_BOARD = "/getVolumeBoard";

export const GET_USER_VOLUME_COUNT = "/getUserVolumeBoardCounts";

//Share
export const SEND_BOARD_INVITATION = "/sendBoardInvitation";
export const LINK_SHARE = "/linkSharing";
export const TOGGLE_PERMISSION = "/changeBoardAccessPermission";
export const VERIFY_BOARD_TOKEN = "/verifyBoardToken";
export const ACCEPT_REJECT_INVITATION = "/acceptRejectInvitation";
export const GET_BOARD_TOTAL = "/getBoardCounts";

// Subscription
export const GET_SUBSCRIPTION_URL = "/listProduct";
export const GET_ACTIVE_SUBSCRIPTION_URL = "/getActiveSubscriptionsBySite";
