import { StateChanger } from "../../../utils";
import {
  LOADING_BOARD_DETAILS,
  SET_BACKGROUND_DETAILS,
  SET_BACKGROUND_IMAGES,
  SET_BACKGROUND_IMAGES_LOADING,
  SET_BOARD_TITLE,
  SET_OWNER_INFO,
  SET_INVITED_USERS,
  SET_IS_INVITED_USER,
  SET_IS_PUBLIC,
  LIST_ADMIN_TEMPLATE_BOARDS_DATA,
  DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_PAGE,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SEARCH,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SIZE_PER_PAGE,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_FIELD,
  SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_ORDER,
  CLEAR_TEMPLATE_TABLE_DATA,
  REMOVE_LOADING_CARD_ID,
  CREATE_UPDATE_CARD_LOADING,
  EDITOR_LIST_CANVAS_BOARD,
  PREVIEW_LIST_CANVAS_BOARD,
  SET_FETCH_BOARD,
  SET_CANVAS,
  SET_CANVAS_CARD_COORDINATE,
  SET_ZOOM_LEVEL,
  SET_UNDO_STACK,
  SET_ACTIVE_CARD_DATA,
  SET_GRID,
} from "./actionTypes";

const initialState = {
  // HEADER
  loading: false,
  title: "Untitled board",
  backgroundData: "",
  backgroundType: 1,
  loadingBackgroundImages: false,
  backgroundImages: [],

  // EDITOR
  boardCards: {},
  zoomLevel: 1,
  canvasLoading: false,
  canvasCoords: {},
  loadingCardIds: [],
  invitedUsers: [],
  ownerInfo: {
    name: "",
    imgUrl: "",
    email: "",
  },
  isInvitedUser: false,
  isPublic: 0,
  undoStack: [],

  //TEMPLATE BOARDS
  templateBoards: {
    rows: [],
    count: 0,
    page: 1,
    sizePerPage: 10,
    sortField: "updated_datetime",
    sortOrder: "DESC",
    search: "",
  },
  duplicateTemplateLoading: false,
  boardLoading: false,
  previewBoardData: [],
  activeCardData: null,
  grid: 1,
};

const boardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const helper = new StateChanger(state);

  switch (type) {
    case SET_BOARD_TITLE:
      return helper.change("title", payload);
    case LOADING_BOARD_DETAILS:
      return helper.change("loading", payload);
    case SET_BACKGROUND_DETAILS:
      return helper.change(
        ["backgroundData", "backgroundType"],
        [payload.backgroundData, payload.backgroundType],
      );
    case SET_BACKGROUND_IMAGES_LOADING:
      return helper.change("loadingBackgroundImages", payload);
    case SET_BACKGROUND_IMAGES:
      return helper.change("backgroundImages", payload);
    case CREATE_UPDATE_CARD_LOADING:
      return helper.change("loadingCreateUpdateCard", payload);
    case EDITOR_LIST_CANVAS_BOARD:
      return helper.change("boardCards", payload);
    case PREVIEW_LIST_CANVAS_BOARD:
      return helper.change("previewBoardData", payload);
    case SET_FETCH_BOARD:
      return helper.change("boardLoading", payload);
    case SET_UNDO_STACK:
      return helper.change("undoStack", payload);
    case SET_CANVAS:
      return helper.change("canvasLoading", payload);
    case SET_CANVAS_CARD_COORDINATE:
      return helper.change("canvasCoords", payload);
    case SET_ZOOM_LEVEL:
      return helper.change("zoomLevel", payload);

    case REMOVE_LOADING_CARD_ID: {
      return helper.change(
        "loadingCardIds",
        state.loadingCardIds.filter(id =>
          Array.isArray(payload) ? !payload.includes(id) : id !== payload,
        ),
      );
    }
    case SET_OWNER_INFO:
      return helper.change("ownerInfo", payload);
    case SET_INVITED_USERS:
      return helper.change("invitedUsers", payload);
    case SET_IS_INVITED_USER:
      return helper.change("isInvitedUser", payload);
    case SET_IS_PUBLIC:
      return helper.change("isPublic", payload);
    case SET_GRID:
      return helper.change("grid", payload);

    //template admin boards

    case LIST_ADMIN_TEMPLATE_BOARDS_DATA:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          rows: payload.rows,
          count: payload.count,
        },
      };
    case SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_PAGE:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          page: payload,
        },
      };
    case SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SIZE_PER_PAGE:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          sizePerPage: payload,
        },
      };
    case SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_FIELD:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          sortField: payload,
        },
      };
    case SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_ORDER:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          sortOrder: payload,
        },
      };
    case SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SEARCH:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          search: payload,
        },
      };
    case DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA:
      return {
        ...state,
        // duplicateTemplateLoading: true,
      };

    case CLEAR_TEMPLATE_TABLE_DATA:
      return {
        ...state,
        templateBoards: {
          ...state.templateBoards,
          page: 1,
        },
      };

    case SET_ACTIVE_CARD_DATA:
      return {
        ...state,
        activeCardData: payload,
      };
    default:
      return state;
  }
};

export default boardReducer;
