// HEADER
export const SET_BOARD_TITLE = "SET_BOARD_TITLE";
export const LOADING_BOARD_DETAILS = "LOADING_BOARD_DETAILS";
export const SET_BACKGROUND_DETAILS = "SET_BACKGROUND_DETAILS";
export const SET_BACKGROUND_IMAGES_LOADING = "SET_BACKGROUND_IMAGES_LOADING";
export const SET_BACKGROUND_IMAGES = "SET_BACKGROUND_IMAGES";

// EDITOR
export const CREATE_UPDATE_CARD_LOADING = "CREATE_UPDATE_CARD_LOADING";
export const DELETE_CARD = "DELETE_CARD";
export const DUPLICATE_CARD = "DUPLICATE_CARD";
export const REORDER_CARDS_WITHIN_ROW = "REORDER_CARDS_WITHIN_ROW";
export const REORDER_CARDS_BETWEEN_ROWS = "REORDER_CARDS_BETWEEN_ROWS";
export const ADD_LOADING_CARD_ID = "ADD_LOADING_CARD_ID";
export const IS_DRAGGING = "IS_DRAGGING";
export const REMOVE_LOADING_CARD_ID = "REMOVE_LOADING_CARD_ID";
export const SET_GRID = "SET_GRID";
export const SET_OWNER_INFO = "SET_OWNER_INFO";
export const SET_INVITED_USERS = "SET_INVITED_USERS";
export const SET_IS_INVITED_USER = "SET_IS_INVITED_USER";
export const SET_IS_PUBLIC = "SET_IS_PUBLIC";
export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL";
//EDITOR CANVAS
export const EDITOR_LIST_CANVAS_BOARD = "EDITOR_LIST_CANVAS_BOARD";
export const PREVIEW_LIST_CANVAS_BOARD = "PREVIEW_LIST_CANVAS_BOARD";
export const SET_FETCH_BOARD = "SET_FETCH_BOARD";
export const SET_CANVAS = "SET_CANVAS";
export const SET_CANVAS_CARD_COORDINATE = "SET_CANVAS_CARD_COORDINATE";

////UNDO REDO
export const SET_UNDO_STACK = "SET_UNDO_STACK";

//TEMPLATE BOARD
export const LIST_ADMIN_TEMPLATE_BOARDS_DATA =
  "LIST_ADMIN_TEMPLATE_BOARDS_DATA";

export const DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA =
  "DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA";
export const DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA_SUCCESS =
  "DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA_SUCCESS";
export const DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA_ERROR =
  "DUPLICATE_ADMIN_TEMPLATE_BOARDS_DATA_ERROR";

export const SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_PAGE =
  "SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_PAGE";
export const SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SIZE_PER_PAGE =
  "SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SIZE_PER_PAGE";
export const SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_FIELD =
  "SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_FIELD";
export const SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_ORDER =
  "SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SORT_ORDER";

export const SET_TEMPLATE_DIGITAL_BOARD_ADMIN_DATA_SEARCH =
  "SET_TEMPLATE_DIGITAL_BOARD_DATA_SEARCH";

export const CLEAR_TEMPLATE_TABLE_DATA = "CLEAR_TEMPLATE_TABLE_DATA";

export const SET_ACTIVE_CARD_DATA = "SET_ACTIVE_CARD_DATA";
